import { envUtils } from '@nbit/utils'
import { InitThemeColor } from '@/constants/base'
import { GetUserLoginAndRegisterMethods, GetWalletMode } from '@/constants/common'

const { EnvTypesEnum, getEnvAwsS3Config } = envUtils

/** 获取是否是 NodeJs 服务器环境 */
export const envIsServer = import.meta.env.SSR
/** 获取是否是客户端环境 */
export const envIsClient = !envIsServer
/** 是否是开发环境 */
export const envIsDev = import.meta.env.VITE_NEWBIT_ENV === EnvTypesEnum.development
/** 是否是测试环境 */
export const envIsTest = import.meta.env.VITE_NEWBIT_ENV === EnvTypesEnum.test
/** 是否是 sg dev 环境 */
export const envIsSGDev = import.meta.env.VITE_NEWBIT_ENV === EnvTypesEnum.dev

export const envIsBuild = !envIsDev
export const cdnUrl = import.meta.env.VITE_MARKCOIN_CDN_URL

export const baseUrl = envIsClient
  ? import.meta.env.VITE_MARKCOIN_BASE_URL
  : import.meta.env.VITE_MARKCOIN_SERVER_BASE_URL

export const noSplicingBaseUrl = import.meta.env.VITE_NO_SPLICING_BASE_URL

// 现货 ws
export const wsUrl = import.meta.env.VITE_MARKCOIN_WS

export const port = import.meta.env.VITE_PORT
// git  最近的 id
export const gitCommitId = import.meta.env.VITE_GIT_COMMIT_ID

/** AWS S3 config */
export const awsS3Config = getEnvAwsS3Config(import.meta.env.VITE_NEWBIT_ENV)
export const newbitEnv = import.meta.env.VITE_NEWBIT_ENV

export const businessId = import.meta.env.VITE_MARKCOIN_BUSINESS_ID
export const accessKeyId = import.meta.env.VITE_MARKCOIN_WEB_ACCESS_KEY_ID
export const ModuleConfig = import.meta.env.VITE_MARKCOIN_MODULE_CONFIG
export const templateId = import.meta.env.VITE_MARKCOIN_TEMPLATE_ID
export const saasId = import.meta.env.VITE_MARKCOIN_SAAS

// monkey business user
export const monkeyBid = '1'
export const isMonkey = businessId === monkeyBid

/** 是否是生产环境 */
export const envIsProd = import.meta.env.VITE_NEWBIT_ENV === EnvTypesEnum.production

// s3 中的主题色
export const systemThemeColor = InitThemeColor[import.meta.env.VITE_MARKCOIN_DEFAULT_THEME] || InitThemeColor.ok

/** 钱包模式 */
export const walletMode = GetWalletMode(import.meta.env.VITE_WALLET_MODE_WEB)

/** 默认语言 */
export const defaultLanguage = import.meta.env.VITE_DEFAULT_LANGUAGE

/** 支持语言 */
export const languagesList = import.meta.env.VITE_LANGUAGES

/** 是否展示手机登录和注册 */
export const mobileVerify = GetUserLoginAndRegisterMethods(import.meta.env.VITE_MOBILE_VERIFY_WEB)
