import cacheUtils from 'store'

export const assetSetting = 'ASSET_SETTING'
export function getAssetSetting() {
  return cacheUtils.get(assetSetting)
}
export function setAssetSetting(val) {
  return cacheUtils.set(assetSetting, val)
}
export function removeAssetSetting() {
  return cacheUtils.set(assetSetting, '')
}

/** 资产 - 充提币 - 币种选择搜索历史 */
export const ASSETS_DEPOSIT_COIN_HISTORY = 'ASSETS_DEPOSIT_COIN_HISTORY'
export function getAssetsDepositCoinHistoryCache() {
  return cacheUtils.get(ASSETS_DEPOSIT_COIN_HISTORY)
}
export function setAssetsDepositCoinHistoryCache(data: any) {
  return cacheUtils.set(ASSETS_DEPOSIT_COIN_HISTORY, data)
}

/** 法币 - 法币选择搜索历史 */
export const CURRENCY_DEPOSIT_COIN_HISTORY = 'CURRENCY_DEPOSIT_COIN_HISTORY'
export function getCurrencyDepositCoinHistoryCache() {
  return cacheUtils.get(CURRENCY_DEPOSIT_COIN_HISTORY)
}
export function setCurrencyDepositCoinHistoryCache(data: any) {
  return cacheUtils.set(CURRENCY_DEPOSIT_COIN_HISTORY, data)
}
