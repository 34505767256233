import request, { MarkcoinRequest } from '@/plugins/request'
import {
  YapiGetV1CoinQueryMainCoinListApiRequest,
  YapiGetV1CoinQueryMainCoinListApiResponse,
} from '@/typings/yapi/CoinQueryMainCoinListV1GetApi'
import {
  YapiGetV1TradePairListApiRequest,
  YapiGetV1TradePairListApiResponse,
} from '@/typings/yapi/TradePairListV1GetApi'

/**
 * 获取币种列表
 */
export const getSymbolLabelInfo: MarkcoinRequest<
  YapiGetV1TradePairListApiRequest,
  YapiGetV1TradePairListApiResponse['data']
> = params => {
  return request({
    path: `/v1/tradePair/list`,
    method: 'GET',
    params,
  })
}

/**
 * 获取所有币种基本信息
 */
export const getAllCoinSymbolInfoList: MarkcoinRequest<
  YapiGetV1CoinQueryMainCoinListApiRequest,
  YapiGetV1CoinQueryMainCoinListApiResponse['data']
> = params => {
  return request({
    path: `v1/coin/queryMainCoinList`,
    method: 'GET',
    params,
  })
}
