import request, { MarkcoinRequest } from '@/plugins/request'
import {
  CurrencyReq,
  ChargeReq,
  ChargeResp,
  PayChargeReq,
  PayChargeResp,
  ChargeInfoReq,
  ChargeInfoResp,
  WithdrawInfoReq,
  WithdrawInfoResp,
  TradeChannelReq,
  TradeChannelResp,
  CurrencyResp,
  PayWithdrawReq,
  PayWithdrawResp,
  PayPreWithdrawReq,
  PayPreWithdrawResp,
  FiatPriceResp,
  FiatDepositTipsReq,
  FiatDepositTipsResp,
  QueryOrderCoinReq,
  QueryOrderCoinResp,
  FinanceOrdersInfoReq,
  FinanceOrdersInfoReqResp,
  FinanceOrdersManualExistReq,
  FinanceOrdersManualExistResp,
  DefaltCurrencyReq,
  DefaltCurrencyResp,
  FinanceOrdersAddReq,
  FinanceOrdersAddResp,
  FinanceOrdersAreaListReq,
  FinanceOrdersAreaListResp,
  FinanceOrdersInfoV2Req,
  FinanceOrdersInfoV2Resp,
  FinanceOrdersAddV2Req,
  FinanceOrdersAddV2Resp,
  FiatPayChargeInfoV3Req,
  FiatPayChargeInfoV3Resp,
  FiatPayWithdrawInfoResp,
  FiatPaywithdrawReq,
  FiatPaywithdrawResp,
  FiatPayPaymentAddReq,
  FiatPayPaymentAddResp,
} from '@/typings/api/assets/fiat'
import {
  ReciveListResp,
  ReciveListReq,
  AreaListReq,
  AvailableAreaItem,
  PaymentGetReq,
  FiatOrderListReq,
  FiatOrderListResp,
  FiatOrderDetailReq,
  FiatOrderDetailResp,
} from '@/typings/api/assets/fiat-payment'

/**
 * 获取法币列表
 * yapi: https://yapi.nbttfc365.com/project/73/interface/api/22051
 */
export const getCurrencyList: MarkcoinRequest<CurrencyReq, CurrencyResp> = params => {
  return request({
    path: `/v1/c2c/pay/fiat/currency`,
    method: 'GET',
    params,
  })
}

/**
 * 充值页面内容【新版】
 * yapi: https://yapi.nbttfc365.com/project/73/interface/api/23207
 */
export const getChargeInfoList: MarkcoinRequest<ChargeReq, ChargeResp> = params => {
  return request({
    path: `/v2/fiat/pay/charge/info`,
    method: 'GET',
    params,
  })
}

/**
 * 充值
 * yapi: https://yapi.nbttfc365.com/project/73/interface/api/22052
 */
export const getPayCharge: MarkcoinRequest<PayChargeReq, PayChargeResp> = data => {
  return request({
    path: `/v1/c2c/pay/charge`,
    method: 'POST',
    data,
  })
}

/**
 * 充值页面内容
 * yapi: https://yapi.nbttfc365.com/project/73/interface/api/22052
 */

export const getChargeInfo: MarkcoinRequest<ChargeInfoReq, ChargeInfoResp> = params => {
  return request({
    path: `/v1/c2c/pay/footer`,
    method: 'GET',
    params,
  })
}

/**
 * 提现页面信息
 * yapi: https://yapi.nbttfc365.com/project/73/interface/api/22053
 */

export const getWithdrawInfo: MarkcoinRequest<WithdrawInfoReq, WithdrawInfoResp> = params => {
  return request({
    path: `/v1/c2c/pay/withdraw/info`,
    method: 'GET',
    params,
  })
}

/**
 * 提现
 * yapi: https://yapi.nbttfc365.com/project/73/interface/api/22056
 */

export const getPayWithdraw: MarkcoinRequest<PayWithdrawReq, PayWithdrawResp> = data => {
  return request({
    path: `/v1/c2c/pay/withdraw`,
    method: 'POST',
    data,
  })
}

/**
 * 法币充提 -获取法币对 USDT 的汇率
 */
export const getFiatPrice: MarkcoinRequest<{ fiatCode: string }, FiatPriceResp> = params => {
  return request({
    path: '/v1/c2c/pay/rate',
    method: 'GET',
    params,
  })
}

/**
 * 法币充提 -获取法币汇率和余额 v3
 */
export const getFiatPriceRate: MarkcoinRequest<{ fiatCode: string; areaCurrency: string }, FiatPriceResp> = params => {
  return request({
    path: '/v3/fiat/pay/rate',
    method: 'GET',
    params,
  })
}

/**
 * 充值/提现入口设置
 */
export const getTradeChannel: MarkcoinRequest<TradeChannelReq, TradeChannelResp> = params => {
  return request({
    path: '/v1/c2c/commonSetting/trade/channel',
    method: 'GET',
    params,
  })
}

/**
 * 提现预检查
 */
export const getPayPreWithdraw: MarkcoinRequest<PayPreWithdrawReq, PayPreWithdrawResp> = params => {
  return request({
    path: '/v1/c2c/pay/pre-withdraw',
    method: 'GET',
    params,
  })
}

/**
 * 提现预检查 V3
 */
export const getFiatPayWithdraw: MarkcoinRequest<PayPreWithdrawReq, PayPreWithdrawResp> = params => {
  return request({
    path: '/v3/fiat/pay/pre-withdraw',
    method: 'GET',
    params,
  })
}

/**
 * 获取默认货币
 */
export const getDefaultCurrency: MarkcoinRequest<{ fiatCode: string }> = params => {
  return request({
    path: '/v1/c2c/pay/default/fiat/currency',
    method: 'GET',
    params,
  })
}

/**
 * 法币充值 - 底部提示信息
 */
export const getFiatDepositTips: MarkcoinRequest<FiatDepositTipsReq, FiatDepositTipsResp> = params => {
  return request({
    path: `/v1/fiat/pay/charge/tips`,
    method: 'GET',
    params,
  })
}

/**
 * 商户信息查询接口
 */

export const getQueryOrderCoin: MarkcoinRequest<QueryOrderCoinReq, QueryOrderCoinResp> = params => {
  return request({
    path: `/v1/coin/queryOrderCoin`,
    method: 'GET',
    params,
  })
}

/**
 * 商户信息查询接口
 */

export const getFinanceOrdersInfo: MarkcoinRequest<FinanceOrdersInfoReq, FinanceOrdersInfoReqResp> = params => {
  return request({
    path: `/v1/finance/orders/info`,
    method: 'GET',
    params,
  })
}

/**
 * 获取是否开启填单存款
 */
export const getFinanceOrdersManualExist: MarkcoinRequest<
  FinanceOrdersManualExistReq,
  FinanceOrdersManualExistResp
> = params => {
  return request({
    path: `/v1/finance/orders/manual/exist`,
    method: 'GET',
    params,
  })
}

export const postFinanceOrdersAdd: MarkcoinRequest<FinanceOrdersAddReq, FinanceOrdersAddResp> = data => {
  return request({
    path: `/v1/finance/orders/add`,
    method: 'post',
    data,
  })
}

/**
 * 获取交易区信息
 * https://yapi.nbttfc365.com/project/44/interface/api/23914
 */
export const getFinanceOrdersAreaList: MarkcoinRequest<
  FinanceOrdersAreaListReq,
  FinanceOrdersAreaListResp
> = params => {
  return request({
    path: `/v1/finance/orders/area/list`,
    method: 'GET',
    params,
  })
}

/**
 * 填单存款信息 v2
 * https://yapi.nbttfc365.com/project/44/interface/api/23932
 */
export const getFinanceOrdersInfoV2: MarkcoinRequest<FinanceOrdersInfoV2Req, FinanceOrdersInfoV2Resp> = params => {
  return request({
    path: `/v2/finance/orders/info`,
    method: 'GET',
    params,
  })
}

/**
 * 填单存款信息 v2
 * https://yapi.nbttfc365.com/project/44/interface/api/23935
 */
export const PostFinanceOrdersInfoV2: MarkcoinRequest<FinanceOrdersAddV2Req, FinanceOrdersAddV2Resp> = data => {
  return request({
    path: `/v2/finance/orders/add`,
    method: 'POST',
    data,
  })
}

/**
 * 默认法币检查接口
 * https://yapi.nbttfc365.com/project/73/interface/api/23929
 */
export const getFiatPayDefaultCurrency: MarkcoinRequest<DefaltCurrencyReq, DefaltCurrencyResp> = params => {
  return request({
    path: `/v1/fiat/pay/default/currency`,
    method: 'GET',
    params,
  })
}

/**
 * 充值页面内容 (V3)
 * https://yapi.nbttfc365.com/project/73/interface/api/23938
 */
export const getFiatPayChargeInfoV3: MarkcoinRequest<FiatPayChargeInfoV3Req, FiatPayChargeInfoV3Resp> = params => {
  return request({
    path: `/v3/fiat/pay/charge/info`,
    method: 'get',
    params,
  })
}

/**
 * 充值页面内容 (V3)
 * https://yapi.nbttfc365.com/project/73/interface/api/23938
 */
export const getFiatPayCharge: MarkcoinRequest<DefaltCurrencyReq, DefaltCurrencyResp> = params => {
  return request({
    path: `/v3/fiat/pay/charge/info`,
    method: 'GET',
    params,
  })
}

/**
 * 充值 V3
 * https://yapi.nbttfc365.com/project/73/interface/api/23944
 */
export const postFiatPayCharge: MarkcoinRequest<PayChargeReq, PayChargeResp> = data => {
  return request({
    path: `/v3/fiat/pay/charge`,
    method: 'POST',
    data,
  })
}

/**
 * 提现页面信息
 * https://yapi.nbttfc365.com/project/73/interface/api/23947
 */
export const getFiatPayWithdrawInfo: MarkcoinRequest<WithdrawInfoReq, FiatPayWithdrawInfoResp> = params => {
  return request({
    path: `/v3/fiat/pay/withdraw/info`,
    method: 'GET',
    params,
  })
}

/**
 * 收款方式列表
 * https://yapi.nbttfc365.com/project/73/interface/api/23953
 */
export const getFiatPayPaymentReciveList: MarkcoinRequest<ReciveListReq, ReciveListResp> = params => {
  return request({
    path: `/v3/fiat/pay/payment/reciveList`,
    method: 'GET',
    params,
  })
}

/**
 * 获取币种支付方式
 * https://yapi.nbttfc365.com/project/73/interface/api/23950
 */
export const getFiatPayPaymentList: MarkcoinRequest<AreaListReq, AvailableAreaItem[]> = params => {
  return request({
    path: `/v3/fiat/pay/payment/list`,
    method: 'GET',
    params,
  })
}

/**
 * 提现
 * https://yapi.nbttfc365.com/project/73/interface/api/23981
 */
export const PostFiatPaywithdraw: MarkcoinRequest<FiatPaywithdrawReq, FiatPaywithdrawResp> = data => {
  return request({
    path: `/v3/fiat/pay/withdraw`,
    method: 'POST',
    data,
  })
}

/**
 * 新增支付方式
 * https://yapi.nbttfc365.com/project/73/interface/api/23956
 */
export const postFiatPayPaymentAdd: MarkcoinRequest<FiatPayPaymentAddReq, FiatPayPaymentAddResp> = data => {
  return request({
    path: `/v3/fiat/pay/payment/add`,
    method: 'POST',
    data,
  })
}

/**
 * 多币种收款方式列表
 * https://yapi.nbttfc365.com/project/73/interface/api/23980
 */
export const getFiatPayPaymentReciveListGroup: MarkcoinRequest<ReciveListReq, ReciveListResp> = params => {
  return request({
    path: `/v3/fiat/pay/payment/reciveList`,
    method: 'GET',
    params,
  })
}

/**
 * 获取收付方式详情
 * https://yapi.nbttfc365.com/project/73/interface/api/23979
 */
export const getFiatPayGet: MarkcoinRequest<PaymentGetReq> = params => {
  return request({
    path: `/v3/fiat/pay/payment/get`,
    method: 'GET',
    params,
  })
}

/**
 * 修改支付方式接口
 * https://yapi.nbttfc365.com/project/73/interface/api/23959
 */
export const postFiatPayPaymentModify: MarkcoinRequest<FiatPayPaymentAddReq, FiatPayPaymentAddResp> = data => {
  return request({
    path: `/v3/fiat/pay/payment/modify`,
    method: 'POST',
    data,
  })
}

/**
 * 法币充提订单列表
 * https://yapi.nbttfc365.com/project/73/interface/api/23994
 */

export const postFiatOrderList: MarkcoinRequest<FiatOrderListReq, FiatOrderListResp> = data => {
  return request({
    path: `/v1/fiat/order/list`,
    method: 'POST',
    data,
  })
}

/**
 * 法币充提订单详情
 * https://yapi.nbttfc365.com/project/73/interface/api/23995
 */

export const getFiatOrderDetail: MarkcoinRequest<FiatOrderDetailReq, FiatOrderDetailResp> = params => {
  return request({
    path: `/v1/fiat/order/detail`,
    method: 'get',
    params,
  })
}
