import request, { MarkcoinRequest } from '@/plugins/request'
import { envUtils } from '@nbit/utils'
import axios from 'axios'
import { businessId, newbitEnv } from '@/helper/env'

const { getEnvS3MaintenanceApiUrl } = envUtils

export const getMaintenanceConfigFromS3: MarkcoinRequest = () => {
  return axios.request({
    baseURL: getEnvS3MaintenanceApiUrl(newbitEnv, businessId),
    method: 'GET',
  })
}

/**
 * 娱乐城维护 - 查询
 * https://yapi.nbttfc365.com/project/44/interface/api/10894
 */
export const getMaintenanceStatus: MarkcoinRequest = () => {
  return request({
    path: `/v1/system/preference/get`,
    method: 'GET',
  })
}
