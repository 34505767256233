import { Message } from '@nbit/arco'
import { getHybridCoinRate, getOverviewAsset, getV2CoinRate } from '@/apis/assets/common'
import { getCoinInfoList, getSubCoinInfoList, checkUserWithdraw } from '@/apis/assets/main'
import {
  AllCoinListResp,
  AssetsListResp,
  CoinRateResp,
  ILegalCurrencyRate,
  IResultAllCoinList,
} from '@/typings/api/assets/assets'
import { baseLayoutStore } from '@/store/layout'
import { baseAssetsStore } from '@/store/assets'
import { formatCurrency, formatNumberDecimal, removeDecimalZero } from '@/helper/decimal'
import { basePersonalCenterStore } from '@/store/user/personal-center'
import { CurrencySymbolEnum, CoinListTypeEnum, CurrencyNameEnum, DefaultRateBaseCoin } from '@/constants/assets'
import { decimalUtils } from '@nbit/utils'
import Decimal from 'decimal.js'
import { storeEnumsToOptions } from '@/helper/store'
import { IStoreEnum } from '@/typings/store/common'
import { t } from '@lingui/macro'
import { baseCommonStore } from '@/store/common'
import { getMergeModeStatus } from '@/features/user/utils/common'
import { showLocaleInfoContent } from '@/constants/common'
import { getAllCoinSymbolInfoList } from '@/apis/spot/common'
import { extractMetaData } from '../layout/metadata'

const SafeCalcUtil = decimalUtils.SafeCalcUtil

type getAllCoinListProps = {
  /** type: 1，充值，2、提币， */
  type: number
  /** 币种数量 */
  coinId?: string | number
}
/**
 * 获取所有主币列表
 */
export const getAllCoinList = async (params: getAllCoinListProps) => {
  const { type = CoinListTypeEnum.deposit, coinId } = params
  const resData: IResultAllCoinList = { coinList: null, coinInfo: null }
  const assetsStore = baseAssetsStore.getState()
  /**
   * 根据币 id 查币信息
   * @param coinList
   * @param id  币 id
   * @returns
   */
  const getCoinInfoByCoinId = (coinList: AllCoinListResp[], id: string | number) => {
    const coinInfo = coinList.filter(item => {
      return `${item.id}` === `${id}`
    })[0]
    if (!coinInfo) return null

    return coinInfo
  }

  /** 获取币币列表数据，将数据存到 store */
  const saveCoinListToStore = async () => {
    const res = await getCoinInfoList({ type })
    const results = res.data?.coinList
    if (res.isOk && results) {
      assetsStore.updateAllCoinInfoList(results)
    }

    return results
  }

  // 先从 store 里取，store 里没值再从接口获取数据
  let coinList: AllCoinListResp[] = assetsStore.allCoinInfoList
  const listData = await saveCoinListToStore()
  if (listData) coinList = listData

  if (coinList && coinList.length > 0) {
    resData.coinList = coinList
    if (coinId) resData.coinInfo = getCoinInfoByCoinId(coinList, coinId)
  }

  return resData
}

/**
 * 获取资产总览
 */
export const getAssetsOverview = async () => {
  const res = await getOverviewAsset({})
  const { isOk, data } = res || {}
  if (!isOk || !data) {
    return null
  }
  return data
}

/**
 * 根据主币获取子币列表
 */
export const getSubCoinList = async (coinId: string) => {
  let params = { coinId }
  const res = await getSubCoinInfoList(params)
  let results = res.data?.subCoinList
  if (res.isOk && results) {
    return results
  }
  return null
}

/**
 * 获取相应币种精度
 * @params symbol
 * @return 当前币种精度，默认为 2 位
 */
export const getCoinPrecision = (symbol: string) => {
  const assetsStore = baseAssetsStore.getState()
  // 异常时默认 2 个精度
  const defaultPrecision = 2
  const { coinRate } = assetsStore.coinRate
  if (!symbol || !coinRate || coinRate.length === 0) {
    return defaultPrecision
  }

  const targetCoin: any = coinRate.find((item: any) => {
    return symbol?.toUpperCase() === (item?.symbol || '').toUpperCase()
  })

  return targetCoin?.coinPrecision || defaultPrecision
}

/**
 * 格式化币种数量 - 处理币种精度，不补零
 * @param symbol 币种符号
 * @param amount 币种数量
 * @param isFormat 是否格式化位千分位展示
 * @returns
 */
export const formatCoinAmount = (symbol, amount, isFormat = true) => {
  amount = `${formatNumberDecimal(amount, getCoinPrecision(symbol))}`
  amount = removeDecimalZero(amount)
  if (isFormat) {
    return formatCurrency(amount, getCoinPrecision(symbol), false)
  }
  return amount
}

/**
 * 查对应法币汇率
 * @param legalCurrencyRate 发布信息
 * @param currencyRate 法币符号
 * @returns
 */
export const getLegalCurrencyRate = (legalCurrencyRate: ILegalCurrencyRate[], currencyRate: string) => {
  return legalCurrencyRate?.find(item => item?.currency?.toUpperCase() === currencyRate?.toUpperCase())?.rate
}

type RateFilterReq = {
  /** 币种数量 */
  amount: string | number
  /** 币种代码 */
  symbol?: string
  /** 换算后金额展示单位 */
  unit?: string
  /** 是否展示换算后金额的单位，默认展示金额单位 */
  showUnit?: boolean
  /** 汇率换算的规则，默认跟随用户设置 */
  rate?: string
  /** 法币精度 默认为 2 */
  precision?: number
  isFormat?: boolean
  /** 法币 symbol */
  currencySymbol?: string
  /** 默认空值显示文案 */
  defaultEmptyText?: string
  /** 折算 symbol(商户设置的法币 symbol) */
  calcSymbol?: string
}
/**
 * 根据相应币种进行汇率换算
 * @param amount 数量
 * @param symbol 币种符号 - 币对换算用标的币符号，默认折合 USDT
 * @param rate 折算法币：默认走用户设置的法币汇率，也可指定的法币汇率 - 如:CNY|| USD
 * @param unit 货币单位：CurrencySymbolEnum.symbol 货币符号 (例：$)，CurrencySymbolEnum.code：货币代码（例:USD），默认货币符号
 * @param showUnit 是否显示货币单位，默认显示
 * @param isFormat 是否千分位格式化，默认不格式化，只有 showUnit 为 false 时生效
 * @param precision 法币精度
 * @returns 汇率换算后的金额
 */
export const rateFilter = (params: RateFilterReq) => {
  const {
    amount,
    symbol = '',
    unit = 'symbol',
    showUnit = true,
    rate = '',
    precision = 2,
    isFormat = false,
    defaultEmptyText = '--',
    calcSymbol,
  } = params

  const assetsStore = baseAssetsStore.getState()
  const { legalCurrencyRate = [] as ILegalCurrencyRate[], coinRate = [] } = assetsStore.coinRate

  // 兼容支持传入法币折算场景
  let fiatCurrencyData = basePersonalCenterStore.getState().fiatCurrencyData
  if (calcSymbol) {
    const {
      currencySymbol = '',
      currencyEnName = '',
      currencyTypeCd = '',
    } = fiatCurrencyData?.currencyList?.find(item => item?.currencyEnName === calcSymbol) || {}
    fiatCurrencyData = { ...fiatCurrencyData, currencySymbol, currencyEnName, currencyTypeCd }
  }
  const { currencySymbol = '', currencyEnName = '' } = fiatCurrencyData || {}

  if (!amount) {
    let newAmount = isFormat ? formatCurrency(amount, precision) : amount ?? defaultEmptyText

    newAmount =
      unit === CurrencySymbolEnum.code
        ? `${newAmount} ${currencyEnName}`
        : (newAmount = `${currencySymbol}${newAmount}`)
    return newAmount
  }

  let newAssets: string | number = amount
  const targetCoin: any = coinRate.find((item: any) => {
    return symbol?.toUpperCase() === (item.symbol || '').toUpperCase()
  })

  if (targetCoin) {
    // 得到当前币种的 usdt 汇率下金额
    newAssets = `${SafeCalcUtil.mul(amount, targetCoin.usdtRate)}`
  }

  // 获取法币设置的汇率 (兼容获取用户设置的汇率或指定法币汇率)
  const currencyRate = legalCurrencyRate.find(item =>
    rate ? item.currency === rate?.toUpperCase() : item.currency === currencyEnName?.toUpperCase()
  )?.rate

  newAssets = `${SafeCalcUtil.mul(newAssets, currencyRate)}`

  if (showUnit) {
    // 金额格式化
    newAssets = formatCurrency(newAssets, precision)

    newAssets =
      unit === CurrencySymbolEnum.code
        ? `${newAssets} ${currencyEnName}`
        : (newAssets = `${currencySymbol}${newAssets}`)
  } else {
    if (isFormat) {
      // 金额格式化
      newAssets = formatCurrency(newAssets, precision)
    }
  }

  return newAssets
}

/**
 * 合约法币资产汇率换算
 * 计算逻辑：合约返回 USD，如果用户设置 USD 直接展示，如果用户设置 CNY，需要转换成 CNY
 * 非 USD 的计算公式：USD 数量 / USDT 汇率 * 用户设置的汇率 -- 先将 USD 数量转成 USDT 的数量，再通过 USDT 汇率折合成对应的法币金额
 * @param amount 合约数量 - 单位 USD
 * @param unit 货币单位：CurrencySymbolEnum.symbol 货币符号 (例：$)，CurrencySymbolEnum.code：货币代码（例:USD），默认货币符号
 * @param showUnit 是否显示货币单位，默认显示
 * @param isFormat 是否千分位格式化，默认格式化，只有 showUnit 为 false 时生效
 * @param precision 法币精度
 * @param symbol 法币符号：当前法币符号，默认合约商户设置法币符号
 * @param rate 折算后法币：默认走用户设置的法币汇率，也可指定的法币汇率 - 如:CNY|| USD
 * @returns 汇率换算后的金额
 */
export const rateFilterFutures = (params: RateFilterReq) => {
  const {
    amount = 0,
    unit = CurrencySymbolEnum.symbol,
    showUnit = true,
    isFormat = true,
    precision = 2,
    symbol = '',
    rate = '',
  } = params

  let newAssets: string | number | Decimal = amount
  const assetsStore = baseAssetsStore.getState()
  // 获取用户设置的法币信息
  const { currencySymbol, currencyEnName } = basePersonalCenterStore.getState().fiatCurrencyData
  const currentSymbol = symbol

  // rate 有值时折算前的法币符号等于折算后的法币符号比较；rate 没值时和用户设置的法币比较，相等直接返回数值
  if (
    (rate && currentSymbol?.toUpperCase() === rate?.toUpperCase()) ||
    (!rate && currentSymbol?.toUpperCase() === currencyEnName?.toUpperCase())
  ) {
    newAssets = amount
  } else {
    const { legalCurrencyRate = [], coinRate = [] } = assetsStore.coinRate
    /** 要折算的法币汇率 - 默认拿用户设置的法币 */
    const currencyRate = getLegalCurrencyRate(legalCurrencyRate, rate || currencyEnName)

    /** USDT 币种汇率 */
    const usdtRateInfo: any = coinRate.find((item: CoinRateResp) => {
      return (item?.symbol || '').toUpperCase() === DefaultRateBaseCoin.symbol?.toUpperCase()
    })

    // 计算公式：USD 数量 / USDT 汇率 * 用户设置的汇率
    if (usdtRateInfo && currencyRate) {
      newAssets = SafeCalcUtil.div(amount, usdtRateInfo.usdtRate)
      newAssets = SafeCalcUtil.mul(newAssets, currencyRate)
    }
  }
  if (isNaN(Number(newAssets))) {
    newAssets = 0
  }
  if (showUnit && isFormat) {
    // 金额格式化
    newAssets = formatCurrency(newAssets, precision)
    if (unit === CurrencySymbolEnum.code) {
      // 货币简称在后面
      newAssets = `${newAssets} ${currencyEnName}`
    } else {
      // 货币符号在前面
      newAssets = `${currencySymbol}${newAssets}`
    }
  } else {
    if (isFormat) {
      // 不展示单位，但是需要千分位格式化
      newAssets = formatCurrency(newAssets, precision)
    }
  }

  return `${newAssets}`
}

type RateFilterCoinQuantityReq = {
  /** 币种数量 */
  amount: string | number
  /** 币种代码 */
  symbol?: string
  /** 换算后金额展示单位 */
  unit?: string
  /** 是否展示换算后金额的单位，默认展示金额单位 */
  showUnit?: boolean
  /** 汇率换算的规则，默认跟随用户设置 */
  rate?: string
  /** 法币精度 默认为 2 */
  precision?: number
  isFormat?: boolean
  /** 法币 symbol */
  currencySymbol?: string
}

/**
 * 法币金额换算成币种数量
 * 法币数量 / 当前法币汇率 / 当前币种汇率
 * @param amount 法币数量
 * @param currencySymbol 法币
 * @param symbol 币种
 */
export const rateFilterCoinQuantity = (params: RateFilterCoinQuantityReq) => {
  const { amount, currencySymbol = CurrencyNameEnum.usd, symbol } = params || {}
  const assetsStore = baseAssetsStore.getState()
  const { legalCurrencyRate = [] as ILegalCurrencyRate[], coinRate = [] as CoinRateResp[] } = assetsStore.coinRate

  if (!amount || !symbol) return 0

  // 查找 currency 为 "USD" 的 rate 值
  const rate = getLegalCurrencyRate(legalCurrencyRate, currencySymbol)

  const coinRateInfo = coinRate.find((item: CoinRateResp) => item?.symbol === symbol) || ({} as CoinRateResp)

  return +SafeCalcUtil.div(SafeCalcUtil.div(amount, rate), coinRateInfo?.usdtRate) || 0
}

/** 法币汇率折合排序 */
export function sortCurrencyAssetsFn(a: AssetsListResp, b: AssetsListResp) {
  return (b.usdBalance as unknown as number) - (a.usdBalance as unknown as number)
}

/** 去除重复的数据，交易页下单成功 ws 回调会有多条数据且有重复的情况 */
export function removeRepeatData(data) {
  if (!Array.isArray(data)) {
    return data
  }
  for (let i = 0; i < data.length; i += 1) {
    for (let j = i + 1; j < data.length; ) {
      if (data[i].coinId === data[j].coinId) {
        data.splice(j, 1)
      } else {
        j += 1
      }
    }
  }
  return data
}

/** 数据字典-修改默认值 */
export const getStoreEnumsToOptions = (enums: IStoreEnum['enums'], labelKey = 'value', valueKey = 'id') => {
  return storeEnumsToOptions(enums, labelKey, valueKey)
}

/**
 * 提币前校验
 */
export const verifyUserWithdraw = async () => {
  const res = await checkUserWithdraw({})
  const { isOk = false, data: { errMsg = '', isOpenSafeVerify = false, isSuccess = false } = {} } = res || {}

  // 接口层面错误
  if (!isOk) {
    // Message.error(message)
    return { isOk, isSuccess, isOpenSafeVerify }
  }
  if (!isSuccess) {
    // 未开启两项验证，跳转用户中心两项验证页面
    if (!isOpenSafeVerify) {
      const assetsStore = baseAssetsStore.getState()
      assetsStore.updateIsOpenSafeVerify(isOpenSafeVerify)
      return { isOk, isSuccess, isOpenSafeVerify }
    }

    // 其他错误信息，直接弹框提示
    Message.error(errMsg)
    return { isOk, isSuccess, isOpenSafeVerify }
  }

  return { isOk, isSuccess, isOpenSafeVerify }
}

/**
 * 获取汇率信息存到 store 里
 */
export const getCoinRateData = async () => {
  const assetsStore = baseAssetsStore.getState()
  const isMergeMode = getMergeModeStatus()
  const res = isMergeMode ? await getHybridCoinRate({}) : await getV2CoinRate({})
  const results = res?.data

  if (!res.isOk || !results) return

  assetsStore.updateCoinRate(results)
}

/**
 * 获取所有币种信息列表
 */
export const getMainCoinData = async () => {
  const { allCoinList, updateAllCoinList } = baseAssetsStore.getState()
  if (allCoinList && allCoinList?.length > 0) return allCoinList

  const res = await getAllCoinSymbolInfoList({})
  const { isOk, data } = res || {}
  if (!isOk || !data) {
    return []
  }

  updateAllCoinList(data?.coinList)
  return data
}

/** 获取娱乐区状态 */
export const getHideRecreation = async () => {
  await baseCommonStore.getState().getIsHideRecreation()
}

/**
 * 处理币币资产信息，加解密、隐藏零额资产、搜索等
 * @param dataList
 * @param searchKey
 * @param hideLessState
 * @returns
 */
export const searchCoinList = (dataList: any | undefined, searchKey: string, hideLessState: boolean) => {
  if (!searchKey && !hideLessState) {
    return dataList
  }

  dataList =
    !!dataList &&
    dataList.filter((item: { coinName: string; totalAmount: number }) => {
      const ignoreCaseKey = searchKey.toUpperCase()
      return item.coinName.toUpperCase().includes(ignoreCaseKey) && (!hideLessState || item.totalAmount > 0)
    })

  return dataList
}

export function getBusinessName() {
  const { layoutProps } = baseLayoutStore.getState()
  const metaData = extractMetaData(layoutProps)
  return metaData.businessName
}

/**
 * 从指定 key 中生成默认的标题和描述，默认带有 businessName 填充，如果有其它需要填充的内容就需要自己单独写了
 * @param keys
 * @returns
 */
export function generateAssetsDefaultSeoMeta(
  // TODO commTitle 备用，后面扩张
  keys: {
    title: string
    description?: string
    commTitle?: string
  },
  values?: any
) {
  const businessName = getBusinessName()
  if (!values) {
    values = { businessName }
  } else {
    values.businessName = businessName
  }
  return {
    title: keys.title,
    description: t({
      id: keys?.description || `modules_assets_company_verified_material_index_page_server_efre42ngx6`,
      values,
    }),
  }
}

/**
 * 法币充提汇率换算
 * @param amount 数量
 * @param rate 法币汇率
 * @param precision 法币精度
 * @param isUSDT 是 usdt 换成其他币种 还是其他币种换算成 usdt
 * @param activityAmount 空投金额
 * @param isThousandths 是否是千分位
 * @returns 汇率换算后的金额
 */

export const faitAmount = (
  amount,
  rate: number,
  isUSDT: boolean,
  precision: number,
  isThousandths,
  activityAmount?
) => {
  if (activityAmount) {
    const activityAmountVal = formatNumberDecimal(
      Math.max(0, Number(SafeCalcUtil.sub(`${SafeCalcUtil.mul(rate, amount)}`, activityAmount))),
      precision
    )
    if (isThousandths) {
      return formatCurrency(activityAmountVal)
    }
    return activityAmountVal
  } else {
    if (isUSDT) {
      const multiplyVal = formatNumberDecimal(SafeCalcUtil.mul(rate, amount), precision)
      if (isThousandths) {
        return formatCurrency(multiplyVal)
      }
      return multiplyVal
    } else {
      const divideVal = formatNumberDecimal(SafeCalcUtil.mul(amount, 1 / (rate ?? 1)), precision)
      if (isThousandths) {
        return formatCurrency(divideVal)
      }
      return divideVal
    }
  }
}

/**
 * 判断是否充值提现
 * isAccount 是否账户
 */
export function getDetermineWhetherMethods(isAccount = false) {
  const { localeInfo } = baseCommonStore.getState()
  if (showLocaleInfoContent(localeInfo?.showDeposit) && showLocaleInfoContent(localeInfo?.showWithdrawal))
    return isAccount
      ? t`features_user_components_sidebar_index__l9rq30wke`
      : t`features_layout_recreation_header_components_wallet_index_mwq8ca1nvc`
  if (showLocaleInfoContent(localeInfo?.showDeposit)) return t`features_home_hero_banner_index_flh6elz9z2`
  return t`features_user_components_sidebar_index_etltjevzl9`
}
