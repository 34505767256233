import { FinancialRecordLogTypeEnum } from '@/constants/assets'
import { baseLayoutStore } from '@/store/layout'
import { link } from '@/helper/link'
import { getIsLogin } from '@/helper/auth'

/** 联系客服 */
export function linkToCustomerService() {
  const url = baseLayoutStore.getState().layoutProps?.customerJumpUrl
  link(url, { target: true })
}

export function linkWithLoginCheck(path: string) {
  if (getIsLogin()) {
    return link(path)
  }

  return link(`login?redirect=${path}`)
}

/** c2c-去充值 */
export function getC2cTopupPageRoutePath() {
  return `/assets/main/deposit`
}

/** c2c-快捷交易 */
export function getC2cOrderShortPageRoutePath() {
  return `/c2c/fast-trade`
}

/** 第三方交易 */
export function getThirdPartyPaymentPageRoutePath() {
  return `/c2c/third-party-payment`
}

/** kyc 中心 */
export function getKycPageRoutePath() {
  return '/kyc-authentication-homepage'
}

/** 资产总览 */
export function getAssetsAllRoutePath() {
  return '/assets'
}

/** 查看资产 */
export function getAssetsRoutePath() {
  return '/assets/c2c'
}

/** 查看财务记录 */
export function getFinancialRecordRoutePath() {
  return `/assets/financial-record?type=${FinancialRecordLogTypeEnum.main}`
}

/** maintenance page */
export function getMaintenanceRoutePath() {
  return '/maintenance'
}

export function getC2cFastTradePageRoutePath() {
  return `/c2c/fast-trade`
}

export function getRecreationPageRoutePath() {
  return `/recreation`
}

/** 投注记录 */
export function getRecreationMyBetPageRoutePath() {
  return '/recreation/my-bet'
}

// ====================================================== //
// ===================== vip module ===================== //
// ====================================================== //

export function getVipTierRoutePath() {
  return '/vip/vip-tier'
}

export function getVipCenterRoutePath() {
  return '/vip/vip-center'
}

export function getVipSettingRoutePath() {
  return '/vip/vip-setting'
}

/** c2c-交易 */
export function getC2cTradeRoutePath() {
  return '/c2c/trade'
}

/** c2c-提币 */
export function getMaiWithdrawRoutePath() {
  return '/assets/main/withdraw'
}

/** 收付款管理 */
export function getPaymentManagementRoutePath() {
  return '/fiat/payment'
}

/** KYC 认证 */
export function getKycManagementRoutePath() {
  return '/kyc-authentication-homepage'
}

/** 登陆页 */
export function getLoginPath() {
  return '/login'
}

/** 用户安全验证中心 */
export function getPersonalAccountSecurityPath() {
  return '/personal-center/account-security'
}

/** 偏好设置 */
export function getPersonalCenterSettingsSecurityPath() {
  return '/personal-center/settings'
}

/** API 管理 */
export function getPersonalCenterSettingsApiSecurityPath() {
  return '/personal-center/settings/api'
}

/** 用户安全验证中心 */
export function getSafetyVerificationPath(searchOriginal?, redirect?) {
  if (searchOriginal || redirect) {
    return `/safety-verification${searchOriginal || `?redirect=${redirect}`}`
  }
  return '/safety-verification'
}

/** 注册认证 */
export function getRegisterVerificationPath(method) {
  return `/register/verification?type=${method}`
}

/** 重置安全项验证 */
export function getSafetyicationPath() {
  return `/safety-items`
}

/** 重置密码 - 人工 */
export function getResetPasswordPath(type?: string) {
  return `/reset-artificial?popup=${type}`
}

/** 安全项验证 */
export function getRestSafetyicationPath() {
  return '/rest-safety-verification'
}

/** 邀请返佣 */
export function getAgentPath() {
  return '/agent'
}

/** 联盟中心 */
export function getAgentCenterPath() {
  return '/agent/agency-center'
}

/** 福利中心 */
export function getWelfareCenterPath() {
  return '/welfare-center'
}

/** 重置密码 */
export function getRetrieveResetPasswordPath(artificial = false) {
  if (artificial) {
    return '/retrieve/reset-password?artificial=true'
  }
  return `/retrieve/reset-password`
}
